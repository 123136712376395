@import '../../../stylesheets/variables.scss';

.container {
	margin: 0 auto;
	width: 100%;
}

.list {
	margin: 0 0 36px;
	padding: 0 0 0 18px;
}

.listItem {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	padding: 0;
}

.label {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
}

.transactionId {
	color: $c_text;
	font-size: 14px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 0 0 16px;
}

.input {
	margin: 0 0 34px;
	width: 100%;
}

.button {
	align-items: center;
	display: flex;
	height: 46px;
	margin: 0 auto;
	max-width: 232px;
	width: 100%;
}

.message {
	margin: 0 0 28px;
}

.instructions {
	color: $c_text;
	margin: 0 0 36px;
	font-size: 12px;
	font-weight: $fw_medium;
}

.timeLeft {
	color: $c_text;
	font-size: 16px;
	font-weight: $fw_medium;
	text-align: center;
	margin: 30px 0;
}

.askForCallButtonContainer {
	margin-top: -26px;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	line-height: 22px;
}

.askForCallButton {
	margin: 0;
	padding-right: 0;
	min-height: 0;
}
