@import '../../stylesheets/variables.scss';

.input {
	margin: 0 0 24px;
}

.button {
	align-items: center;
	display: flex;
	height: 46px;
	@include variable(buttonMargin, margin, none);
	max-width: 232px;
	width: 100%;
}

.paragraph {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	text-align: center;
}

.link {
	color: $c_button;
	display: inline-block;
	font-size: 12px;
	font-weight: $fw_bold;
	line-height: 22px;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_button_hover;
	}
}

.message {
	margin: 0 0 28px;
}
