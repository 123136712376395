@import '../../stylesheets/variables.scss';

.list {
	font-size: 0;
	list-style: none;
	margin: 0 auto;
	max-width: 350px;
	padding: 0;
	text-align: center;
	width: 100%;
}

.listItem {
	display: block;
	margin-bottom: 17px;

	&:last-child {
		margin-bottom: 0;
	}
}

.disabled {
	opacity: 0.5;
}
