@import '../../../stylesheets/variables.scss';

.body {
	background-color: #fff;
	left: 50%;
	margin: 0 auto;
	max-height: 100%;
	max-width: 1032px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 20px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 101;

	@media (min-width: 992px) {
		padding: 68px 72px;
	}
}

.closeButton {
	padding: 0;
	margin: 0 0 50px;
	align-self: flex-start;
}

.title {
	color: $c_text;
	font-size: 21px;
	font-weight: 500;
	margin: 12px 0 24px;
}

.text {
	color: $c_black;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 40px;

	a {
		color: $c_primary;
		font-weight: bold;
		text-decoration: none;
	}
}

.toggle {
	margin-bottom: 20px;

	@media (min-width: 992px) {
		margin-bottom: 40px;
	}
}

.toggleLabel {
	font-weight: 400;
	font-size: 14px;
	padding: 0 40px 0 0;

	@media (min-width: 992px) {
		padding: 0 110px 0 0;
	}
}

.agreeButton {
	display: flex;
	align-items: center;
	align-self: flex-end;
	font-size: 16px;
	font-weight: 500;
	height: 50px;
	justify-content: center;
	margin: 40px auto 30px;
	min-width: 240px;
	text-transform: none;

	@media (min-width: 992px) {
		margin: 10px 0 0 auto;
	}
}

.backdrop {
	background-color: $c_black;
	bottom: 0;
	left: 0;
	opacity: 0.5;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
}
