@import '../../stylesheets/variables.scss';

.qrContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 10px;

	.secret {
		color: $c_primary;
		font-size: $fs_large;
		font-weight: $fw_medium;
	}

	.qr {
		display: flex;
		position: relative;
		width: fit-content;
		padding: 8px;

		&::before,
		&::after,
		> :first-child:before,
		> :first-child:after {
			position: absolute;
			content: ' ';
			width: 16px;
			height: 16px;
			border-color: $c_primary;
			border-style: solid;
		}

		&:before {
			top: 0;
			left: 0;
			border-width: 3px 0 0 3px;
			border-radius: 8px 0 0 0;
		}
		&:after {
			top: 0;
			right: 0;
			border-width: 3px 3px 0 0;
			border-radius: 0 8px 0 0;
		}

		.indicator {
			&:before {
				bottom: 0;
				right: 0;
				border-width: 0 3px 3px 0;
				border-radius: 0 0 8px 0;
			}
			&:after {
				bottom: 0;
				left: 0;
				border-width: 0 0 3px 3px;
				border-radius: 0 0 0 8px;
			}
		}
	}
}

.info {
	text-align: left;
	line-height: 22px;

	ul {
		display: flex;
		flex-flow: column;

		li {
			display: flex;

			&::before {
				display: flex;
				content: '⬤';
				font-size: 6px;
				margin-right: 10px;
				align-self: flex-start;
			}

			a {
				text-decoration: none;
				color: $c_primary;
				font-weight: $fw_medium;
			}
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.loadingIcon {
	display: flex;
	justify-content: center;

	.animation {
		animation: spin 1000ms infinite linear;
	}
}
