@import '../../../../stylesheets/variables.scss';

.button {
	margin-top: 12px;
	min-width: 40%;
	font-weight: $fw_medium;
	font-size: $fs_medium;
}

.paragraph {
	text-align: center;
	font-size: $fs_xxlarge;
	font-weight: $fw_medium;
}

.image {
	max-width: 300px;
	height: 160px;
}
