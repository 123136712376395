@import '../../../stylesheets/variables.scss';

.paragraphContainer {
	font-size: 14px;
	line-height: 22px;
}

.paragraph {
	color: $c_text;
	font-size: 14px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 18px;
	margin: 30px 0;
}

.button {
	margin-top: 12px;
	width: 100%;
}

.highlight {
	color: $c_button;
	font-weight: $fw_bold;
}

.link {
	background: none;
	border: none;
	color: $c_primary;
	cursor: pointer;
	font-size: 14px;
	font-weight: $fw_bold;
	margin: 0;
	outline: none;
	padding: 0;
	text-decoration: none;
	transition: $tr_fast;

	&:hover,
	&:focus {
		color: $c_blue_200;
	}
}
