@import '../../../stylesheets/variables.scss';

.icon {
	margin: 0 auto 32px;
	width: 252px;
}

.heading {
	color: $c_text;
	font-size: 20px;
	font-weight: $fw_bold;
	line-height: 26px;
	margin: 0 0 24px;
}

.paragraph {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 0 0 40px;
}

.button {
	font-size: 14px;
	height: 46px;
	margin: 0 auto;
	max-width: 232px;
	width: 232px;
}

.link {
	font-size: 14px;
}
