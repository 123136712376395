.hcaptchaOverlayBadge {
	position: fixed;
	bottom: 14px;
	z-index: 9998;
	cursor: pointer;
}

.hcaptchaOverlayBadge img {
	width: 60px;
	height: auto;
}

.hcaptchaOverlayPopup {
	position: fixed;
	font-size: 10px;
	bottom: 25px;
	left: 60px;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 10px;
	width: 300px;
	z-index: 9999;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
