.disclaimer {
	display: flex;
	justify-content: center;
	margin-bottom: 70px;

	p {
		max-width: 910px;
		font-size: 12px;
		line-height: 15px;
		color: #acacac;
	}

	@media (max-width: 1440px) {
		padding: 0 20px;
	}
}
