@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Red+Hat+Text:400,500,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import './variables.scss';

$teroxxFonts: (
	400: 'soehne-buch',
	500: 'soehne-kraftig',
	600: 'soehne-halbfett',
	700: 'soehne-dreiviertelfett',
);

@each $weight, $file in $teroxxFonts {
	@font-face {
		font-family: 'Söhne';
		src: url('https://assets.teroxx.world/Soehne_Font_11_2024/WOFF2/#{$file}.woff2')
			format('woff2');
		font-weight: $weight;
		font-style: normal;
		font-display: swap;
	}
}

* {
	box-sizing: border-box;
	@include variable(fontFamily, font-family, none);
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 7px;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-track:horizontal {
		background: $c_gray_300 0 0% no-repeat padding-box;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:horizontal {
		background-color: $c_primary;
		border-radius: 10px;

		&:hover {
			background-color: $c_primary_hover;
		}
	}
}

html,
body {
	margin: 0;
	@include variable(backgroundColor, background-color, none);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.globalErrorContainer {
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.globalErrorContainer > * {
	max-width: 50%;
	margin: 0;
}

#recaptcha {
	position: absolute;
	top: 50vh;
	left: calc(50vw - 116px);
	width: 232px;
}

.alterRecaptchaElement {
	opacity: 0;
}

.alterLiveChatElementElement {
	top: -14px;
}
