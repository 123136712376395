@import '../../stylesheets/variables.scss';
$google_background_color: #d24a37;
$facebook_background_color: #1977f3;
$apple_background_color: #000000;

.button {
	align-items: center;
	border-radius: 4px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	margin: 0 auto;
	max-width: 230px;
	height: 45px;
	min-height: 46px;
	min-width: 232px;
	opacity: 1;
	outline: none;
	transition: $tr_fast;
	width: 100%;
	background: transparent;
	border: 1px solid $c_button_disabled;

	&:hover:enabled,
	&:focus:enabled {
		border-color: $c_button;
	}

	&:disabled {
		background: transparent;
		cursor: default;
		border-color: $c_button_disabled;
		color: $c_button_disabled;
	}

	//&.google {
	//	background-color: $google_background_color;
	//
	//	&:hover:enabled,
	//	&:focus:enabled {
	//		background-color: lighten($google_background_color, 5%);
	//	}
	//}
	//
	//&.facebook {
	//	background-color: $facebook_background_color;
	//
	//	&:hover:enabled,
	//	&:focus:enabled {
	//		background-color: lighten($facebook_background_color, 5%);
	//	}
	//}
	//
	//&.apple {
	//	background-color: $apple_background_color;
	//}
}

.text {
	color: $c_text;
	font: normal normal bold 12px/16px Poppins;
	text-align: center;
	display: block;
	width: 100%;
	margin-right: 20px;
}

.icon {
	font-size: 20px;
	width: 20px !important;
	margin: 0 14px 0 8px;
}

.iconImage {
	height: 18px;
	width: auto;
	margin: 0 14px 0 8px;
}
