@import '../../stylesheets/variables.scss';

.button {
	display: block;
	margin: 0 auto 8px;
	max-width: 280px;
	width: 100%;

	@media (min-width: 768px) {
		max-width: unset;
	}
}

.select {
	margin: 0 0 24px;
}

.checkbox {
	align-items: flex-start;
	display: flex;
	justify-content: center;
	margin: 0 0 24px;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}
}

.checkboxLabel {
	color: $c_text;
	font-size: 10px;
	font-weight: $fw_medium;
	line-height: 13px;
	margin: 0 10px;
}

.span {
	font-weight: $fw_regular;
}

.policyLink {
	color: $c_primary;
	display: inline-block;
	font-size: 10px;
	font-weight: $fw_bold;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_blue_200;
	}
}
