@import '../../../../stylesheets/variables.scss';

.content {
	font-size: 12px;
	line-height: 22px;
}

.input {
	margin-bottom: 24px;
}

.askForCallButtonContainer {
	margin-top: -16px;
	display: flex;
	justify-content: space-between;
}

.askForCallButton {
	margin: 0;
	padding-right: 0;
	min-height: 0;
}

.buttonContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 24px auto 0;
}

.button {
	margin: 10px auto;
	width: 100%;
}

.message {
	margin-top: 16px;
}

.recaptcha {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
}
