@import '../../../../stylesheets/variables.scss';

.button {
	display: block;
	margin: 0 auto 8px;
	width: 100%;

	font-size: 12px;
	@include variable(actionsFontWeight, font-weight, none);
}

.input {
	margin: 0 0 32px;
}

.select {
	margin: 0 0 24px;
}

.iti.iti--container {
	max-width: 350px;

	@media (max-width: 768px) {
		max-width: 240px;
	}
}

.signUpLinkWrapper {
	color: $c_text;
	font-size: 12px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 18px;
	margin: 24px 0 24px;
	text-align: center;
}

.link {
	color: $c_button;
	display: inline-block;
	font-size: 12px;
	@include variable(actionsFontWeight, font-weight, none);
	line-height: 18px;
	text-decoration: none;
	transition: $tr_fast;

	&.block {
		display: block;
	}

	&:hover {
		color: $c_button_hover;
	}
}

.checkbox {
	display: flex;
	justify-content: center;
	margin: 0 0 40px;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}
}

.checkboxLabel {
	color: $c_text;
	font-size: 12px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 15px;
	margin: 0 10px;
}

.span {
	font-weight: $fw_regular;
}

.policyLink {
	color: $c_button;
	display: inline-block;
	font-size: 12px;
	font-weight: $fw_bold;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_button_hover;
	}
}

.message {
	margin: 0 0 24px;
}

.socialLoginContainer {
	margin-top: 10px;
	margin-bottom: 24px;
}

.loginLinkWrapper {
	color: $c_text;
	font-size: 12px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 22px;
	margin: 24px 0 0;
	text-align: center;
}

.separator {
	align-items: center;
	color: #c7cdd1;
	display: flex;
	font-size: 12px;
	font-weight: $fw_medium;
	margin: 20px 0;
	text-align: center;

	&::before {
		border-bottom: 2px solid #e6e8f0;
		content: '';
		flex: 1;
		margin-right: 10px;
	}

	&::after {
		border-bottom: 2px solid #e6e8f0;
		content: '';
		flex: 1;
		margin-left: 10px;
	}
}
