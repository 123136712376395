@import '../../stylesheets/variables.scss';

.description {
	color: $c_text;
	line-height: 20px;
	margin: 0 0 36px;
	font-size: 12px;
	font-weight: $fw_medium;
}

.button {
	align-items: center;
	display: flex;
	height: 46px;
	margin: 0 auto;
	max-width: 232px;
	width: 100%;
}
