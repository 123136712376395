@import '../../stylesheets/variables.scss';

.iti {
	width: 100%;
	font-size: 14px;

	ul {
		max-width: 250px;

		@media (min-width: 425px) and (max-width: 767px) {
			max-width: 350px;
		}

		@media (min-width: 1024px) {
			max-width: 350px;
		}
	}
}

.iti input {
	border-radius: 4px;
	border: 1px solid $c_gray_300;
	font-size: 14px;
	min-height: 40px;
	padding: 11px 12px;
	transition: $tr_fast;
	appearance: none;
	box-shadow: 0 0 0 0 $c_gray_300;
	width: 100%;

	&::placeholder {
		color: $c_gray_400;
		font-size: 14px;
	}

	&:focus {
		outline: 0;
		border: 1px solid $c_primary;
		box-shadow: 0 0 0 1px $c_primary;
	}
}

.iti--label {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 0 0 4px;
	display: block;
}
