@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-direction: column;
}

.label {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 0 0 8px;
}

.inputWrapper {
	position: relative;
}

.input {
	appearance: none;
	border-radius: 4px;
	border: 1px solid $c_gray_300;
	box-shadow: 0px 0px 0px 0px $c_gray_300;
	font-size: 14px;
	min-height: 40px;
	outline: none;
	padding: 11px 12px;
	transition: $tr_fast;
	width: 100%;

	&::placeholder {
		color: $c_gray_400;
		font-size: 14px;
	}

	&:focus {
		border: 1px solid $c_primary;
		box-shadow: 0px 0px 0px 1px $c_primary;
		outline: 0;
	}
}

.countdown {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	position: absolute;
	right: 12px;
	top: 50%;
	min-width: 35px;
	transform: translateY(-50%);

	body[dir='rtl'] & {
		left: 12px;
		right: auto;
	}
}

.retryButton {
	font-size: 13px;
	padding: 0;
}

.time {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
}
