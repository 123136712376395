@import '../../../stylesheets/variables.scss';

.paragraphContainer {
	font-size: 14px;
	line-height: 22px;
	word-wrap: break-word;
	width: 100%;
}

.paragraph {
	color: $c_text;
	font-size: 14px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 18px;
	margin: 30px 0;
}

.buttonContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 24px auto 0;
}

.message {
	margin-bottom: 46px;
}

.button {
	align-items: center;
	display: flex;
	height: 46px;
	margin: 0 auto;
	max-width: 232px;
	width: 100%;
}
