.spinnerContainer {
	align-items: center;
	display: flex;
	flex: 1 1 auto;
	height: 100%;
	justify-content: center;
	width: 100%;

	&.fullScreen {
		min-height: 100vh;
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		justify-content: flex-start;
	}
}

.spinner {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: auto;
	text-align: center;
	width: 70px;
}

.spinner > div {
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	border-radius: 100%;
	display: inline-block;

	&.primary {
		background-color: #c8c8c8;
	}

	&.secondary {
		background-color: #f8f8fa;
	}
}

.spinner .bounce1 {
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}

.XS {
	width: 6px * 4;

	& > div {
		width: 6px;
		height: 6px;
	}
}

.SM {
	width: 10px * 4;

	& > div {
		width: 10px;
		height: 10px;
	}
}

.XL {
	width: 18px * 4;

	& > div {
		width: 18px;
		height: 18px;
	}
}
