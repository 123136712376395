@import '../../stylesheets/variables.scss';

.container {
	align-items: center;
	justify-content: center;
	cursor: pointer;
	display: flex;
	font-size: 12px;
	font-weight: $fw_medium;
	margin: 0 0 15px 0;
	user-select: none;
	flex-wrap: wrap;

	span {
		order: 2;
	}
}

.container .input {
	cursor: pointer;
	height: 0;
	opacity: 0;
	position: absolute;
	width: 0;
}

.checkmark {
	border: 1px solid;
	border-color: $c_gray_300;
	border-radius: 2px;
	height: 18px;
	order: 1;
	position: relative;
	width: 18px;
	min-width: 18px;
	margin-right: 5px;
	flex: 0 0 18px;
}

.error .checkmark {
	border: 1px solid $c_red;
}

.container:hover .input ~ .checkmark,
.container:focus .input ~ .checkmark,
.input:focus ~ .checkmark {
	border-color: $c_primary;
}

.container.error:hover .input ~ .checkmark,
.container.error:focus .input ~ .checkmark,
.error .input:focus ~ .checkmark {
	border-color: $c_red;
}

.checkmark::after {
	content: '';
	display: none;
	position: absolute;
}

.container .input:checked ~ .checkmark::after {
	display: block;
}

.container .checkmark::after {
	border: solid $c_primary;
	border-width: 0 3px 3px 0;
	height: 10px;
	left: 4px;
	top: 0;
	transform: rotate(45deg);
	width: 5px;
}

.label {
	flex: 1 1 0;
}

.error {
	margin-top: 5px;
}
