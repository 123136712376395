@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-direction: column;
}

.label {
	color: $c_text;
	font-size: 12px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 22px;
	margin: 0 0 4px;
}

.inputContainer {
	position: relative;
}

.input {
	@include variable(inputBorderRadius, border-radius, none);
	@include variable(inputBorder, border, none);
	@include variable(inputBGColor, background-color, none);
	width: 100%;
	@include variable(inputFontSize, font-size, none);
	font-weight: 500;
	min-height: 40px;
	padding: 11px 17px;
	transition: $tr_fast;
	appearance: none;
	box-shadow: 0 0 0 0 $c_gray_300;

	&::placeholder {
		color: $c_input_placeholder;
		@include variable(inputFontSize, font-size, none);
	}

	&:not(:placeholder-shown) {
		@include variable(inputBorderFilled, border, none);
	}

	&:hover {
		@include variable(inputBorderHover, border, none);
	}

	&:focus {
		outline: 0;
		border: 1px solid $c_primary;
		box-shadow: 0 0 0 1px $c_primary;
	}
}

.error .input {
	border: 1px solid $c_red;

	&:focus {
		outline: 0;
		border: 1px solid $c_red;
		box-shadow: 0 0 0 1px $c_red;
	}
}

.passwordIcon {
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
	cursor: pointer;

	body[dir='rtl'] & {
		left: 12px;
		right: auto;
	}

	* {
		fill: $c_input_placeholder;

		&:hover {
			fill: $c_primary;
		}
	}

	&.active {
		* {
			fill: $c_primary;

			&:hover {
				fill: $c_primary_hover;
			}
		}
	}
}
