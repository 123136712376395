.error {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.message {
	justify-content: center;
	align-items: center;
	display: flex;
	background: #f2dede;
	text-align: center;
	color: #dd4b64;
	padding: 15px;
	border: 1px solid #e4b9c0;
	width: clamp(300px, 50%, 500px);
	margin: auto;
}
