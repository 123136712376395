@import '../../../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-flow: column;
	@include legacyGap(24px, column);
}

.token {
	@include variable(titleColor, color, black);
	background-color: #f5f8ff;
	border-radius: 8px;
	padding: 8px 16px;
	font-size: $fs_small;
	line-height: 22px;
}

.button {
	min-width: 75%;
	font-weight: $fw_medium;
	font-size: $fs_medium;
}

.flag {
	height: 20px !important;
	width: 20px !important;
	border-radius: 100%;
}

.phone {
	display: flex;
	align-items: center;
	@include legacyGap(8px, row);
}

[dir='rtl'] {
	.phone {
		flex-flow: row-reverse;
		justify-content: flex-end;
	}
}
