@mixin variable($variable, $property, $fallback) {
	// Fallback for browsers that don't support css vars
	// @include variable(backgroundColor, background, none);
	#{$property}: $fallback;
	#{$property}: var(--#{$variable});
}

// colors
$c_primary: var(--c_primary);
$c_primary_hover: var(--c_primary_hover);
$c_primary_light: var(--c_primary_light);

$c_blue_200: #5048bf;
$c_gray_100: #808080;
$c_gray_300: #e8e9ec;
$c_gray_400: #c7cdd1;
$c_green: var(--c_green);
$c_red: var(--c_red);
$c_white: #ffffff;
$c_black: #000000;

$c_text: var(--c_text);
$c_input_placeholder: var(--c_input_placeholder);

$c_button: var(--c_button);
$c_button_hover: var(--c_button_hover);
$c_button_disabled: var(--c_button_disabled);

// font-weight
$fw_regular: 400;
$fw_medium: 500;
$fw_bold: 700;

// font-size
$fs_xsmall: 10px;
$fs_small: 12px;
$fs_medium: 14px;
$fs_large: 16px;
$fs_xlarge: 18px;
$fs_xxlarge: 20px;

// transitions
$tr_fast: all 0.1s, visibility 0s;
$tr_default: all 0.3s ease, visibility 0s;

@mixin legacyGap($size, $direction) {
	> * {
		&:not(:last-child) {
			@if $direction == 'row' {
				margin-right: $size;
			}
			@if $direction == 'column' {
				margin-bottom: $size;
			}
		}
	}
}
