@import '../../../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-flow: column;
	@include legacyGap(24px, column);
}

.button {
	min-width: 75%;
}
