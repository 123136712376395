@import '../../../stylesheets/variables.scss';

.notificationContainer {
	align-items: center;
	@include variable(notificationBGColor, background, none);
	border-radius: 4px;
	border: 1px solid;
	@include variable(notificationBorderColor, border-color, none);
	display: flex;
	justify-content: center;
	padding: 9px 16px;
}

.notificationImage {
	height: auto;
	margin-right: 14px;
	width: 50px;
}

.notificationTextContainer {
	font-size: 14px;
	@include variable(notificationTextColor, color, $c_text);
	@include variable(actionsFontWeight, font-weight, none);
	line-height: 15px;

	p {
		margin: 0;
	}
}

.paragraphContainer {
	font-size: 12px;
	line-height: 22px;
	margin: 10px 0 8px 0;
}

.paragraph {
	color: $c_text;
	font-size: 14px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 18px;
	margin: 30px 0;
}

.input {
	margin-bottom: 28px;
}

.button {
	display: block;
	margin: 34px auto 0;
	width: 100%;
}
