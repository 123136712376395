@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-direction: column;
}

.label {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_bold;
	line-height: 22px;
	margin: 0 0 8px;
}
