@import '../../../stylesheets/variables.scss';

.imageContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.message {
	margin-top: 24px;
}

.bodyContainer {
	font-size: 12px;
	line-height: 22px;
	margin: 10px auto 8px;
}

.button {
	display: block;
	margin: 34px auto 0;
	width: 100%;
}

.continueAsContainer {
	color: $c_gray_100;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 18px;
	margin: 52px 0 8px;
}

.usernameContainer {
	color: $c_text;
	font-size: 16px;
	font-weight: $fw_bold;
	line-height: 18px;
	margin: 8px 0 36px;
	overflow-wrap: break-word;
	word-wrap: break-word;
}
