@import '../../../stylesheets/variables.scss';

.button {
	display: block;
	margin: 0 auto 8px;
	width: 100%;
}

.input {
	margin: 0 0 32px;
}

.select {
	margin: 0 0 24px;
}

.signUpLinkWrapper {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	margin: 24px 0 24px;
	text-align: center;
}

.link {
	color: $c_button;
	display: inline-block;
	font-size: 12px;
	font-weight: $fw_bold;
	line-height: 22px;
	text-decoration: none;
	transition: $tr_fast;

	&.block {
		display: block;
	}

	&:hover {
		color: $c_button_hover;
	}
}

.checkbox {
	align-items: flex-start;
	display: flex;
	justify-content: center;
	margin: 0 0 40px;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}
}

.checkboxLabel {
	color: $c_text;
	font-size: 10px;
	font-weight: $fw_medium;
	line-height: 13px;
	margin: 0 10px;
}

.span {
	font-weight: $fw_regular;
}

.policyLink {
	color: $c_button;
	display: inline-block;
	font-size: 10px;
	font-weight: $fw_bold;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_button_hover;
	}
}

.message {
	margin: 0 0 24px;
}

.socialLoginContainer {
	margin-top: 10px;
	margin-bottom: 24px;
}
