@import '../../stylesheets/variables.scss';

.container {
	&::before {
		content: '';
		@include variable(modalBGColor, background-color, none);
		@include variable(modalOpacity, opacity, none);
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 10;
		width: 100vw;
		height: 100vh;
	}
}

.content {
	position: fixed;
	bottom: 0;
	z-index: 11;

	background-color: $c_white;
	width: 100%;
	padding: 8px 30px;
	display: flex;
	flex-direction: column;
	border-radius: 8px 8px 0px 0px;
}

.close {
	align-self: center;
	width: 50px;
	height: 4px;
	cursor: pointer;
	background-color: $c_gray_300;
	border-radius: 8px;
}

.title {
	margin-top: 24px;

	&.BANKERA {
		font-size: 16px;
		text-align: center;
		font-weight: $fw_bold;
	}

	&.SPECTROCOIN {
		font-size: 14px;
		font-weight: $fw_medium;
	}
}

.row {
	display: flex;
	align-items: center;

	&.BANKERA {
		margin: 24px 0 16px 0;

		&.row + .row {
			margin-top: 0;
			margin-bottom: 32px;
		}
	}

	&.SPECTROCOIN {
		margin: 24px 0;

		&.row + .row {
			margin-top: 0;
		}
	}
}

.name {
	flex: 1;
	font-size: 14px;
	margin: 0 16px;
	@include variable(titleColor, color, none);

	&.BANKERA {
		font-weight: $fw_bold;
	}

	&.SPECTROCOIN {
		font-weight: $fw_medium;
	}
}

.button {
	&.BANKERA {
		width: 106px;
		min-height: 36px;
	}

	&.SPECTROCOIN {
		width: 100px;
		min-height: 40px;
		font-weight: $fw_medium;
		font-size: 14px;
	}
}

.icon {
	display: flex;
	align-items: center;

	svg {
		width: 100%;
		height: 100%;
	}
}
