@import '../../../stylesheets/variables.scss';

.notificationContainer {
	align-items: center;
	border-radius: 4px;
	border: 1px solid $c_green;
	color: $c_green;
	font-weight: 500;
	display: flex;
	justify-content: center;
	padding: 9px 16px;
}

.notificationTextContainer {
	font-size: 14px;
	line-height: 22px;

	p {
		margin: 0;
	}
}

.paragraphContainer {
	font-size: 12px;
	line-height: 22px;
	color: $c_text;
	font-weight: 500;
	margin-top: 16px;

	ul {
		padding: 0 20px;
		margin: 20px 0;
	}
}

.paragraph {
	color: $c_text;
	font-size: 14px;
	@include variable(labelsFontWeight, font-weight, none);
	line-height: 18px;
	margin: 30px 0;
}

.input {
	margin-bottom: 28px;
}

.button {
	display: block;
	margin: 34px auto 0;
	width: 100%;
}
