@import '../../stylesheets/variables.scss';

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-items: center;
	margin: 0 auto;
	max-width: 1048px;
	position: relative;
}

.logo {
	display: block;
	max-width: 150px;
	max-height: 60px;
	margin: 40px auto 95px;

	@media (max-width: 1024px) {
		margin: 32px auto;
	}
}

.languageSwithcer {
	position: absolute;
	right: 0;
	top: 40px;

	@media (max-width: 1024px) {
		top: 32px;
	}
}

.body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin: 0 0 40px;

	&.singleColumn {
		margin: auto;
	}

	@media (min-width: 768px) {
		flex-direction: row;
	}
}

.title {
	@include variable(titleColor, color, none);
	@include variable(titleFontSize, font-size, none);
	@include variable(titleWeight, font-weight, none);
	@include variable(titleAlign, text-align, none);
	line-height: 28px;
	margin: 0 0 24px;
}

.textLeft {
	text-align: left;
}

.zeroMargin {
	margin: 0;
}

.formContainer {
	@include variable(mainCardContainerBorder, border, none);
	@include variable(mainCardContainerBorderRadius, border-radius, none);
	@include variable(mainCardContainerShadow, box-shadow, none);
	@include variable(mainCardContainerBGColor, background-color, none);
	display: flex;
	flex-direction: column;
	@include variable(mainCardFormFlexAlignItems, align-items, none);
	padding: 16px 24px 40px;

	form {
		@include variable(mainCardFormWidth, width, none);
		@include variable(mainCardFormMargin, margin, none);
	}

	@media (min-width: 768px) {
		@include variable(mainCardContainerWidth, max-width, none);
		@include variable(mainCardContainerWidth, width, none);
		@include variable(mainCardContainerPadding, padding, none);
		margin: 0 auto;
	}
}

.formImage {
	height: auto;
	margin: 0 auto;
	max-width: 128px;
	width: 100%;
	margin-bottom: 18px;
}

.image {
	max-width: 390px;
	max-height: 560px;

	@media (max-width: 768px) {
		display: none;
	}
}

.imageContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;

	@media (min-width: 1070px) {
		flex-direction: column;
		@include variable(rightImageMargin, margin, none);
		max-width: 390px;
		width: 390px;
	}
}

.paragraph {
	color: $c_text;
	font-size: 14px;
	font-weight: $fw_regular;
	line-height: 18px;
	margin: 30px 0;

	@media (min-width: 768px) {
		margin: 0;
	}
}

.link {
	color: $c_primary;
	display: inline-block;
	font-size: 14px;
	font-weight: $fw_bold;
	line-height: 22px;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_blue_200;
	}
}

.socialButtons {
	margin-top: 60px;
}

.headerContainer {
	display: inline-flex;
	padding: 20px 0;
}

.center {
	justify-content: center;
}

.left {
	justify-content: flex-start;

	img {
		@media (max-width: 1023px) {
			margin-left: 24px;
		}
	}
}

.right {
	justify-content: flex-end;

	img {
		@media (max-width: 1023px) {
			margin-right: 24px;
		}
	}
}

.logoContainer {
	width: 200px;
}

.appStoreLinkContainer {
	display: flex;
	justify-content: center;

	@media (max-width: 768px) {
		margin-top: 50px;
	}
}

.appStoreLink {
	flex: 0 0 auto;
}

.appStoreImage {
	height: 40px;
	width: auto;
	margin: 0 8px;
}
