@import '../../../../stylesheets/variables.scss';

.emailInput {
	margin: 0 0 32px;
}

.passwordInput {
	margin: 0 0 42px;
}

.link {
	color: $c_button;
	display: inline-block;
	font-size: 12px;
	font-weight: $fw_bold;
	line-height: 22px;
	text-decoration: none;
	transition: $tr_fast;

	&:hover {
		color: $c_button_hover;
	}
}

.forgotPasswordLink {
	margin: 0;
	text-align: center;
	display: block;
}

.button {
	display: block;
	@include variable(buttonMargin, margin, none);
	width: 100%;
}

.button + .button {
	margin: 0 auto 28px;
}

.signUpLinkWrapper {
	color: $c_text;
	font-size: 12px;
	font-weight: $fw_medium;
	line-height: 22px;
	text-align: center;
	margin: 0 0 12px;
}

.socialButton {
	margin: 0 auto 16px;
}

.paragraph {
	color: $c_text;
	font-size: 14px;
	font-weight: $fw_regular;
	line-height: 18px;
	margin: 30px 0;

	@media (min-width: 768px) {
		margin: 0;
	}
}

.message {
	margin-bottom: 28px;
}

.hidden {
	display: none;
}

.separator {
	align-items: center;
	color: #c7cdd1;
	display: flex;
	font-size: 12px;
	font-weight: $fw_medium;
	margin: 20px 0;
	text-align: center;

	&::before {
		border-bottom: 2px solid #e6e8f0;
		content: '';
		flex: 1;
		margin-right: 10px;
	}

	&::after {
		border-bottom: 2px solid #e6e8f0;
		content: '';
		flex: 1;
		margin-left: 10px;
	}
}
