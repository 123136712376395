@import '../../../stylesheets/variables.scss';

.bodyTextContainer {
	line-height: 22px;
	margin-bottom: 15px;
}

.button {
	margin-top: 12px;
	width: 100%;
}

.appStoreLinkContainer {
	display: flex;
	justify-content: center;
}

.appStoreLink {
	flex: 0 0 auto;
}

.appStoreImage {
	height: 40px;
	width: auto;
	margin: 0 8px;
}

.paragraph {
	font-size: 12px;
	text-align: center;
}

.justGoToContainer {
	margin-top: 15px;
	text-align: center;
}
