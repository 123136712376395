@import '../../stylesheets/variables.scss';

.labeled {
	display: flex;
	@include legacyGap(8px, column);

	&.row {
		flex-flow: row;

		> * {
			flex: 1;
		}
	}

	&.column {
		flex-flow: column;
	}

	label {
		@include variable(titleColor, color, black);
		font-size: $fs_small;
		font-weight: $fw_medium;
	}

	span {
		font-size: $fs_medium;
		word-break: break-word;
	}
}
