@import '../../stylesheets/variables.scss';

.container {
	align-items: flex-start;
	color: $c_black;
	cursor: pointer;
	display: flex;
	font-size: 12px;
	font-weight: $fw_medium;
	justify-content: space-between;
	user-select: none;
	width: auto;

	&:hover .slider {
		background-color: rgba($c_primary, 0.25);
	}
}

.toggle {
	display: inline-block;
	height: 16px;
	margin: 0 16px 0 0;
	position: relative;
	width: 34px;
	flex: 0 0 auto;

	&:hover .slider {
		background-color: rgba($c_primary, 0.25);
	}
}

.slider {
	background-color: $c_white;
	border-radius: 16px;
	border: 1px solid #a4afb7;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.4s;

	&::before {
		background-color: #a4afb7;
		border-radius: 50%;
		bottom: 1px;
		content: '';
		height: 12px;
		left: 2px;
		position: absolute;
		transition: 0.4s;
		width: 12px;
	}
}

.textSlider {
	border: 0;
	color: $c_text;
	font-size: 16px;
	text-align: center;
}

.input {
	height: 0;
	opacity: 0;
	width: 0;

	&:checked + .slider {
		background-color: $c_white;
	}

	&:checked + .slider::before {
		transform: translateX(16px);
		background-color: $c_primary;
	}

	&:disabled + .slider {
		background-color: #f2f2f2;
	}
}
